
import { TYPE, useToast } from 'vue-toastification'

export function useToastManager() {
    const display = (message: string, variant: TYPE = 'success' as TYPE, timeout: number | false = 8000) => {
        const toast = useToast();
        toast(message, {type : variant, timeout: timeout})
    }

    return { display };
}
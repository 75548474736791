import { useAppHelpers } from "@/composables/useAppHelpers";
import { useAuthStore } from "@/stores/auth-store";
import axios, { AxiosInstance, AxiosResponse } from "axios";
import router from "@/router";
import { useHelperStore } from "@/stores/helper-store";

axios.defaults.withCredentials = true;

const apiClient: AxiosInstance = axios.create({
    baseURL: "/api/",
    headers: {
        "Access-Control-Allow-Credentials": "True",
        "X-Requested-With": "XMLHttpRequest",
        "Content-type": "application/json",
        Accept: "application/json",
        withCredentials: "true",
    },
});

apiClient.interceptors.request.use(function (config) {
    config.headers["X-XSRF-TOKEN"] = decodeURIComponent(
        useAppHelpers().getCookie("XSRF-TOKEN")
    );
    return config;
});

apiClient.interceptors.response.use(
    (response: AxiosResponse) => {
        if (response.status >= 200 && response.status < 300) {
            return response;
        } else {
            handleErrorResponse(response);
            return Promise.reject(response);
        }
    },
    (error: any) => {
        let response = error.response;
        handleErrorResponse(response);
        return Promise.reject(error);
    }
);

const handleErrorResponse = (response: any) => {
    if (response.status == 419) {
        useAppHelpers().deleteAllCookies("XSRF-TOKEN");
        apiClient.get("/sanctum/csrf-cookie").then(async (csrfResponse) => {});
    }
    if (response.status == 404) {
        useHelperStore().change404State(true);
    }
    if (response.status == 401) {
        if (useAuthStore().isLoggedIn) {
            useAuthStore().processLogout();
        }
    }
    if (response.status == 444) {
        if (useAuthStore().role == 'admin' || useAuthStore().role == 'manager') {
            router.push({ name: 'manager_dashboard' });
        }
        else {
            router.push({ name: "dashboard" });
        }
    }
};

export default apiClient;


<template>
    <div>
        <b-modal v-if="callstore.callModal" @hidden="$emit('closeCallControl')" :no-close-on-backdrop="true" v-model="show" hide-header hide-footer :no-close-on-esc="true" ref="webRTCModal" class="v-modal-custom">

            <!-- Top Buttons -->
            <div class="mb-4">
                <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">

                    <div class="avatar-sm btn btn-light cursor-pointer rounded-circle d-flex justify-content-center align-items-center" data-bs-toggle="dropdown" data-bs-target="#call_menu_dropdown"> <i class="bx bx-dots-vertical-rounded fs-20 fw-medium"></i> </div>
                    <div class="dropdown-menu dropdown-menu-top dropdown-menu-lg p-0 mt-1" id="call_menu_dropdown">
                        <div class="fs-14 fw-medium px-3 py-2">Speakers</div>
                        <ul class="list-group list-group-flush">
                            <li v-for="(device, index) in audioOutDevices" class="list-group-item d-flex align-item-center cursor-pointer" @click="changeAudioOutDevice(device)"><span>{{ device.label }}</span><i v-if="selectedAudioOutDevice && selectedAudioOutDevice.deviceId == device.deviceId" class="bx bx-check-circle fs-16 ms-2"></i></li>
                        </ul>
                        <div class="fs-14 fw-medium px-3 py-2">Microphone</div>
                        <ul class="list-group list-group-flush">
                            <li v-for="(device, index) in audioInDevices" class="list-group-item d-flex align-item-center cursor-pointer" @click="changeAudioInDevice(device)"><span>{{ device.label }}</span><i v-if="selectedAudioInDevice && ((selectedAudioInDevice.deviceId == device.deviceId) || (device?.micId && selectedAudioInDevice.micId == device.micId))" class="bx bx-check-circle fs-16 ms-2"></i></li>
                        </ul>
                    </div>

                    <span class="d-none d-sm-flex" v-if="dialPadState">
                        <select class="form-select form-select-lg" v-model="numberForCall">
                            <option value="">Select Phone Number to Use</option>
                            <option v-for="phone_number in phone_numbers" :value="phone_number.phone_number" :key="'use_phone_' + phone_number.id" :class="phone_number.is_primary == 1 ? 'bg-soft-success' : ''">{{ helper.phoneNumberFormatter(phone_number.phone_number) }} {{ phone_number.label ? '(' + phone_number.label + ')' : '' }}</option>
                        </select>
                    </span>

                    <div class="avatar-sm btn btn-light cursor-pointer rounded-circle d-flex justify-content-center align-items-center" @click="hideCallModal"> <i class="bx bx-x fs-20 fw-medium"></i> </div>

                </div>
            </div>

            <!-- Dialpad -->
            <div class="keypad_container" v-if="dialPadState">
                <span class="d-flex d-sm-none mb-1" v-if="dialPadState">
                    <select class="form-select form-select-lg" v-model="numberForCall">
                        <option value="">Select Phone Number to Use</option>
                        <option v-for="phone_number in phone_numbers" :value="phone_number.phone_number" :key="'use_phone_' + phone_number.id" :class="phone_number.is_primary == 1 ? 'bg-soft-success' : ''">{{ helper.phoneNumberFormatter(phone_number.phone_number) }} {{ phone_number.label ? '(' + phone_number.label + ')' : '' }}</option>
                    </select>
                </span>
                <div class="keypad_row">
                    <input type="text" id="output" :disabled="dialing" autofocus class="form-control" v-model="output" />
                </div>
                <div v-for="row in keypadRows" :key="row.id" class="keypad_row">
                    <div v-for="digit in row.digits" :key="digit.id" class="digit border" @click="handleDigitClick(digit)">
                        {{ digit.label }}
                    </div>
                </div>
                <div class="keypad_row mt-2">
                    <div id="call" class="digit border" @click="call(numberForCall, output)">
                        <i class="bx bx-phone" aria-hidden="true"></i>
                    </div>
                    <div class="digit border" @click="backspace">
                        <i class="bx bx-arrow-back" aria-hidden="true"></i>
                    </div>
                </div>
                <div class="text-primary mt-2 text-center w-100 d-flex" v-if="dialing">Dialing ...</div>
            </div>

            <!-- Active Call Info -->
            <div v-if="!dialPadState && !allCallSectionView && calls.filter((call) => call.callDBObj).length">

                <div class="d-flex flex-column justify-content-center align-items-center mb-3" v-if="activeCall?.contact">
                    <img :src="helper.getProfilePic(activeCall?.contact.attachments && activeCall?.contact.attachments[0] && activeCall?.contact.attachments[0].link ? activeCall?.contact.attachments[0].link : '', activeCall?.contact.first_name + ' ' + activeCall?.contact.last_name, activeCall?.contact.id)" class="rounded-circle avatar-md mb-2" alt="">
                    <div class="fs-18">{{ activeCall.contact.first_name }} {{ activeCall.contact.last_name }}</div>
                    <div class="text-muted fs-14">{{ helper.phoneNumberFormatter(activeCall.contact.phone) }}</div>
                </div>

                <div v-if="activeCall?.contact" class="d-flex flex-column justify-content-center align-items-center">

                    <div v-if="activeCall?.type == 'inbound'" class="fs-14 fw-medium text-capitalize text-primary">Incoming</div>
                    <div v-if="activeCall?.type == 'outbound'" class="fs-14 fw-medium text-capitalize text-danger">Outgoing</div>

                    <div v-if="activeCall?.status" class="fs-14 fw-medium border rounded px-2 text-capitalize text-success" :class="'text-' + getStatusColor(activeCall?.status)">{{ activeCall?.status }}</div>

                    <div v-if="activeCall?.status == 'active' || activeCall?.status == 'held'">{{ formattedTime }}</div>

                    <div class="mt-3 text-center">
                        <div class="fs-13 text-underline text-muted">{{ activeCall?.type == 'inbound' ? 'Received On' : 'Dialed From' }}</div>

                        <div v-if="activeCall.phone?.label" class="text-dark">{{ activeCall.phone?.label }}</div>

                        <div class="text-muted">{{ helper.phoneNumberFormatter(activeCall.phone?.phone_number) }}</div>
                    </div>

                    <div class="mt-3 text-center" v-if="activeCall.phone?.recording_enabled && activeCall.phone?.call_recording_enabled">
                        <div class="text-warning fs-13 fw-medium">Recording Enabled</div>
                        <small>$0.002 per min will be charged for recording.</small>
                    </div>

                </div>

            </div>

            <!-- All Calls Card -->
            <div class="p-2 card-body shadow border d-flex flex-wrap gap-3" v-if="allCallSectionView && calls.filter((call) => call.callDBObj).length">
                <div>
                    <h5>List of All Active, Held, Incoming or Outgoing Calls</h5>
                </div>
                <SimpleBar ref="simplebarInstance" @created="instance => { simplebarInstance = instance; }" style="max-height:210px; width: 100%;">
                    <div class="row g-1">
                        <div class="col-sm-6">
                            <div v-for="(call, index) in calls" class="d-flex border rounded py-1 ps-1 pe-2 cursor-pointer" style="width: max-content;" :class="call.contact?.id == activeCall?.contact?.id ? 'bg-light' : ''" @click="switchCall(call.contact?.id)">
                                <div class="d-flex me-2 ms-0">
                                    <img :src="helper.getProfilePic(call?.contact?.attachments && call?.contact.attachments[0] && call?.contact?.attachments[0].link ? call?.contact?.attachments[0].link : '', call?.contact?.first_name + ' ' + call?.contact?.last_name, call?.contact?.id)" class="rounded-circle avatar-sm" alt="">
                                </div>
                                <div class="d-flex flex-column justify-content-between">
                                    <div class="d-flex flex-column">
                                        <span class="fs-13 fw-medium">{{ call.contact?.first_name }} {{ call.contact?.last_name }}</span>
                                        <span class="text-muted small">{{ helper.phoneNumberFormatter(call.contact?.phone) }}</span>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center w-100">
                                        <span class="fs-12 text-capitalize badge rounded-pill border" :class="'badge-soft-' + getStatusColor(call.status)">{{ call.status }}</span>
                                        <span>
                                            <i v-if="call.type == 'inbound'" v-b-tooltip.hover title="Incoming" class="bx bx-phone-incoming text-primary"></i>
                                            <i v-if="call.type == 'outbound'" v-b-tooltip.hover title="Outgoing" class="bx bx-phone-outgoing text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SimpleBar>
            </div>

            <!-- Call Buttons -->
            <div class="mt-4" v-if="calls.filter((call) => call.callDBObj).length">
                <div class="d-flex flex-row justify-content-around align-items-center flex-wrap">

                    <div v-if="activeCall?.callJSObj.state === 'ringing' || activeCall?.callJSObj.state === 'active'" class="avatar-sm btn btn-danger bg-soft-danger cursor-pointer rounded-circle d-flex justify-content-center align-items-center" @click="hangUp" v-b-tooltip.hover="'End Call'"> <i class="bx bx-phone-off fs-20 fw-medium"></i> </div>

                    <div v-if="activeCall && (activeCall.status == 'active' || activeCall.status == 'held')" class="avatar-sm btn btn-light cursor-pointer rounded-circle d-flex justify-content-center align-items-center" @click="toggleHoldCall" v-b-tooltip.hover="activeCall.status == 'held' ? 'Unhold' : 'Hold'"> <i class="bx fs-20 fw-medium" :class="activeCall.status == 'held' ? 'bx-play-circle' : 'bx-pause-circle'"></i> </div>

                    <div class="avatar-sm btn btn-light cursor-pointer rounded-circle d-flex justify-content-center align-items-center" :class="dialPadState ? 'bg-light' : ''" @click="toggleDialPad" v-b-tooltip.hover="'Toggle Dialpad'"> <i class="bx bx-dialpad fs-20 fw-medium"></i> </div>

                    <div v-if="activeCall" class="avatar-sm btn btn-light cursor-pointer rounded-circle d-flex justify-content-center align-items-center" :class="activeCall?.muted ? 'bg-light' : ''" @click="toggleMuteCall(activeCall?.contact?.id)" v-b-tooltip.hover="'Toggle Microphone'"> <i class="bx  fs-20 fw-medium" :class="activeCall?.muted ? 'bx-microphone-off' : 'bx-microphone'"></i> </div>

                    <div class="avatar-sm btn btn-light cursor-pointer rounded-circle d-flex justify-content-center align-items-center" v-if="activeCall?.phone && activeCall?.phone?.recording_enabled && activeCall?.phone?.call_recording_enabled" @click="disableRecording(activeCall?.phone.id)" v-b-tooltip.hover="'Disable Recording'"> <i class="bx fs-20 fw-medium" :class="disableRecordClick ? 'bx-loader-alt bx-spin' : 'text-red bxs-circle'"></i></div>

                    <div class="avatar-sm btn btn-light cursor-pointer rounded-circle d-flex justify-content-center align-items-center" v-if="activeCall?.phone && !(activeCall?.phone?.recording_enabled && activeCall?.phone?.call_recording_enabled)" @click="enableRecording(activeCall?.phone.id)" v-b-tooltip.hover="'Enable Recording'"> <i class="bx bxs-circle fs-20 fw-medium" :class="disableRecordClick ? 'bx-loader-alt bx-spin' : 'bxs-circle'"></i> </div>

                    <div class="avatar-sm btn btn-light cursor-pointer rounded-circle d-flex justify-content-center align-items-center" :class="allCallSectionView ? 'bg-light' : ''" @click="toggleAllCalls" v-b-tooltip.hover="'Toggle All Calls View'">
                        <i class="bx bx-grid-alt fs-20 fw-medium"></i>
                        <span v-if="calls.length > 0" class="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-success"><span>{{ calls.length }}</span><span class="visually-hidden">active calls</span></span>
                    </div>

                    <div v-if="activeCall?.callJSObj.state === 'ringing'" class="avatar-sm btn btn-success bg-soft-success cursor-pointer rounded-circle d-flex justify-content-center align-items-center" @click="answer" v-b-tooltip.hover="'Answer Call'"> <i class="bx bx-phone-incoming fs-20 fw-medium"></i> </div>

                </div>
            </div>

        </b-modal>
    </div>

    <audio ref="callAudio" autoplay="true" loop="false" class="d-none">
    </audio>
</template>

<script setup lang='ts'>

import type { SimplebarInstanceRef } from 'simplebar-vue3';
import { CallObject, PhoneNumber } from '@/utils/types';
import { onBeforeUnmount, onMounted, ref, watch, watchEffect, onUnmounted } from 'vue';
import { INotification, TelnyxRTC } from '@telnyx/webrtc';
import { BModal } from 'bootstrap-vue-next';
import { TYPE } from 'vue-toastification';
import { IWebRTCInfo } from '@telnyx/webrtc/lib/src/Modules/Verto/webrtc/interfaces';

import { useAppHelpers } from '@/composables/useAppHelpers';
import { useHttpErrorManager } from '@/composables/useHttpErrorManager';
import { useToastManager } from '@/composables/useToastManager';

import { useAuthStore } from '@/stores/auth-store';
import { useCallStore } from '@/stores/call-store';

import { SimpleBar } from "simplebar-vue3";
import { socket } from '@/socket';
import apiClient from '@/utils/axios';

const emit = defineEmits(['closeCallControl'])

const callstore = useCallStore();
const helper = useAppHelpers();
const toast = useToastManager();
const authStore = useAuthStore();

const simplebarInstance = ref<SimplebarInstanceRef>(null);

const calls = ref<Array<CallObject>>(callstore.callEvents); // list of all calls
const activeCall = ref<CallObject | null>(null);
const intervalId = ref<any>(null); // For updating timer
const formattedTime = ref('');

const phone_numbers = ref<Array<PhoneNumber>>([]); // list of all phone number
const numberForCall = ref<string>(''); // number to use for making outbound call

const callClient = ref<TelnyxRTC>(); // WebRTC Client
const callClientStatus = ref<string>('Connecting...');
const callAudio = ref<HTMLAudioElement>(); // HTML Element for playing Audio
const callAudioRingElement = ref(); // HTML Element that is played when incoming call is received

// ----------------------------------------
// Switching the Active Call using Active Call ID
// ----------------------------------------
watch(() => callstore.activeCallId, (newValue) => {
    if (newValue != null) {
        let filteredCall = calls.value.filter((call) => {
            return call.contact?.id == newValue;
        });
        filteredCall.forEach(call => {
            activeCall.value = call;
        });
    }
    else {
        activeCall.value = null;
    }
}, {
    immediate: true
});

// ----------------------------------------
// Updating the Call Events Array from Call Store
// ----------------------------------------
watch(() => callstore.callEvents, (newValue) => {
    calls.value = newValue;
    if (calls.value.length > 0) {
        if (!intervalId.value) {
            intervalId.value = setInterval(updateFormattedTime, 1000);
        }
    }
    else if (calls.value.length == 0) {
        if (intervalId.value) {
            clearInterval(intervalId.value);
            intervalId.value = null;
        }
    }
}, { deep: true });

// ----------------------------------------
// Calculating Call Formatted Time
// ----------------------------------------
const updateFormattedTime = () => {
    if (activeCall.value?.startTime) {
        const seconds = getCallTime(activeCall.value);
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        formattedTime.value = `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    } else {
        formattedTime.value = '';
    }
};

const getCallTime = (call) => {
    if (call.startTime) {
        const startTime = call.startTime;
        const currentTime = (new Date()).getTime();
        const seconds = Math.ceil((currentTime - startTime) / 1000);
        return seconds;
    } else {
        return 0;
    }
}

// ----------------------------------------
// Disconnecting All Outbound Calls when Balance is low
// ----------------------------------------
watch(() => callstore.triggerOutboundDisconnection, (newValue) => {
    if (callstore.triggerOutboundDisconnection) {
        if (callClient.value) {
            calls.value.forEach((call) => {
                if (call.type == 'outbound') {
                    callEndProcess(call.callJSObj);
                    call.callJSObj?.hangup();
                }
            });
            // toast.display('Low Balance', 'info' as TYPE);
            callstore.resetDisconnectOutboundTrigger();
        }
    }
});

// ----------------------------------------
// Initiate Call from different location within the App
// ----------------------------------------
watch(() => callstore.directOutboundCall, (newValue) => {
    if (callstore.directOutboundCall.length > 0) {
        call(callstore.directOutboundCall[0].phone, callstore.directOutboundCall[0].contact);
        callstore.cleanDirectOutboundCallArray();
    }
}, { deep: true });

// ----------------------------------------
// Hiding Call Modal
// ----------------------------------------
const hideCallModal = () => {
    if (dialPadState.value) {
        callstore.toggleDialpad();
    }
    if (allCallSectionView.value) {
        allCallSectionView.value = false;
    }

    callstore.hideCallModal();
}

// ----------------------------------------
// Show/Hide Dialpad
// ----------------------------------------
const dialPadState = ref(false);
watch(() => callstore.dialPad, (newValue) => {
    dialPadState.value = newValue;
}, { immediate: true });

const toggleDialPad = () => {
    if (!dialPadState.value && allCallSectionView.value) {
        allCallSectionView.value = false;
    }
    callstore.toggleDialpad();
}

// ----------------------------------------
// Making New Call Ringing Modal Notification Appear on User Screen
// ----------------------------------------
const showCallRinging = () => {
    dialPadState.value = false;
    allCallSectionView.value = false;
    callstore.hideDialpad();
}

// ----------------------------------------
// Show/Hide All Calls
// ----------------------------------------
const allCallSectionView = ref(false);
const toggleAllCalls = () => {
    if (!allCallSectionView.value && dialPadState.value) {
        callstore.toggleDialpad();
    }
    allCallSectionView.value = !allCallSectionView.value;
}

// ----------------------------------------
// List of All Sip Error Codes with Messages
// ----------------------------------------
const sipErrorMessages = ref({
    // Redirection
    300: "Multiple choices - Select a preferred endpoint to redirect the request.",
    301: "Moved permanently - New address provided in the contact header field.",
    302: "Moved temporarily - New address provided in the contact header field.",
    305: "Use proxy - Access required destination through the specified proxy.",
    380: "Alternate service - Call failed, alternatives described in the message body.",

    // Client Error
    400: "Bad request - Request could not be understood.",
    401: "Not authorized - Request requires user authentication.",
    402: "Payment required - Payment is needed before processing.",
    403: "Forbidden - The server understood the request, but it refuses to authorize it.",
    404: "Not found - User does not exist at that particular domain.",
    405: "Method not allowed - The method specified in the request is not allowed.",
    406: "Not acceptable - Server cannot produce a response matching the list of acceptable values.",
    407: "Proxy authentication required - Client must authenticate with the proxy.",
    408: "Request timeout - Server could not produce a response within a suitable time frame.",
    409: "Conflict - The request could not be completed due to a conflict with the current state of the target resource.",
    410: "Gone - The requested resource is no longer available.",
    411: "Length required - The server requires a content-length in the request.",
    412: "Conditional request failed - One or more conditions in the request header fields evaluated to false.",
    413: "Request entity too large - The server is refusing to process a request because the request entity is larger than the server is willing or able to process.",
    414: "Request URI too long - The server is refusing to service the request because the request-target is longer than the server is willing to interpret.",
    415: "Unsupported media type - Server is refusing to service the request due to an unsupported message body format.",
    416: "Unsupported URI scheme - The server cannot process the request because the request-target uses an unsupported URI scheme.",
    417: "Unknown resource priority - The server is refusing to service the request because the resource's priority is unknown.",
    420: "Bad extension - Bad SIP Protocol Extension used, not understood by the server.",
    421: "Extension required - The extension is mandatory, but not listed in the supported header.",
    422: "Session interval too small - The UAS or UAC that created the request did not understand the requirement of the session interval.",
    423: "Interval too brief - Expiration time of the resource is too short.",
    424: "Bad location information - Location in the request header is not valid for the target.",
    425: "Bad alert message - The alert information in the request is not valid.",
    428: "Use identity header - The server requires that all SIP requests include the Identity header.",
    429: "Provide referrer identity - The server requires that the client provides the Referrer Identity header.",
    430: "Flow failed - Server has received a request in a dialog that was not established by that request.",
    433: "Anonymity disallowed - The request was rejected because the server was unwilling to accept the request without disclosure of the target.",
    436: "Bad identity-info - Server received a request with a bad Identity-Info header.",
    437: "Unsupported certificate - The server was unable to validate a certificate for the domain that signed the request.",
    438: "Invalid identity header - The server received a request with an invalid Identity header.",
    439: "First hop lacks outbound support - The first outbound proxy server to be contacted in a SIP request lacks the capability to fulfill that request.",
    440: "Max breadth exceeded - The server has reached the maximum breadth for a target.",
    469: "Bad info package - A SIP request was received that could not be understood by the server.",
    470: "Consent needed - The user needs to grant consent for a particular action.",
    480: "Temporarily unavailable - The callee's end system was contacted successfully, but the callee is currently unavailable.",
    481: "Call/transaction does not exist - The server received a request that does not match any dialog or transaction.",
    482: "Loop detected - The server has detected a loop.",
    483: "Too many hops - The server received a request that has too many hops.",
    484: "Address incomplete - The server received a request that is missing the required To header field.",
    485: "Ambiguous - The request was ambiguous.",
    486: "Busy here - The callee's end system was contacted successfully but the callee is busy.",
    487: "Request terminated - The request has terminated by a BYE or CANCEL request.",
    488: "Not acceptable here - The callee's end system does not support the audio or video capability.",
    489: "Bad event - The server did not understand an event package specified in an Event header field.",
    491: "Request pending - Some aspect of the session description or the request is still pending.",
    493: "Undecipherable - The server cannot decrypt the request.",
    494: "Security agreement withheld - The server has received a request for which it does not possess a security agreement.",

    // Server Error
    500: "Server internal error - Server prevented from fulfilling the request by an unexpected condition.",
    501: "Not implemented - Required functionality is not supported by the server.",
    502: "Bad gateway - Server received an invalid response from a downstream server.",
    503: "Service unavailable - Server is currently unable to process the request.",
    504: "Server timeout - Server did not receive a timely response from the external server.",
    505: "version not supported",
    513: "message too large",
    555: "push notification service not supported",
    580: "precondition failure",

    // Global Error
    600: "Busy everywhere - Callee's end system is busy.",
    603: "Decline - Callee's end system indicates the user does not wish to or cannot participate.",
    604: "Does not exist anywhere - Information in the request URI does not exist.",
    606: "Not acceptable - User wishes to communicate but cannot adequately support the session described.",
    607: "Unwanted",
    608: "Rejected",
});

// ----------------------------------------
// Code to Create and Manage DialPad and it's associated events
// ----------------------------------------
const count = ref<number>(0);
const output = ref<string>('');
const keypadRows = ref([
    {
        id: 1, digits:
            [
                { id: 'one', label: '1' },
                { id: 'two', label: '2' },
                { id: 'three', label: '3' }
            ]
    },
    {
        id: 2, digits:
            [
                { id: 'four', label: '4' },
                { id: 'five', label: '5' },
                { id: 'six', label: '6' }
            ]
    },
    {
        id: 3, digits:
            [
                { id: 'seven', label: '7' },
                { id: 'eight', label: '8' },
                { id: 'nine', label: '9' }
            ]
    },
    {
        id: 4, digits:
            [
                { id: 'zero', label: '0' },
                { id: 'plus', label: '+' }
            ]
    },
]);

const handleDigitClick = (digit) => {
    if (!dialing.value) {
        if (count.value < 13) {
            output.value += digit.label.trim();
            count.value++;
        }
        checkOutputLength();
    }
}

const backspace = () => {
    if (output.value.length > 0) {
        output.value = output.value.slice(0, -1);
        count.value--;
    }
    if (count.value < 0) {
        count.value = 0;
    }
}

const checkOutputLength = () => {
    count.value = output.value.length;
    if (output.value.length > 13) {
        output.value = output.value.slice(0, -1);
        count.value--;
    }
    if (count.value < 0) {
        count.value = 0;
    }
}

watch(output, (newVal) => {
    checkOutputLength();
});

// ----------------------------------------
// Get Status Color for all connected calls
// ----------------------------------------
const getStatusColor = (status) => {
    if (status == 'active') {
        return 'success';
    }
    if (status == 'held') {
        return 'info';
    }
    else {
        return '';
    }
}

// ----------------------------------------
// Fetching User Phone Numbers to use for making outbound calls
// ----------------------------------------
const fetchPhoneNumbers = async () => {
    await apiClient.get('phoneNumber/getPhoneNumbersForMessaging')
        .then(async (response: { data: { phone_numbers: Array<PhoneNumber> } }) => {
            phone_numbers.value = response.data.phone_numbers;
        })
        .catch(async (error) => {
            await useHttpErrorManager().handleError(error, true);
        });
}

// ----------------------------------------
// Browser notificaiton for new Calls
// ----------------------------------------
const callNotification = (name, number) => {
    number = useAppHelpers().phoneNumberFormatter(number) ? useAppHelpers().phoneNumberFormatter(number) : '';
    if (!("Notification" in window)) {
        alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
        const notification = new Notification("Incoming Call from " + name, { body: number });
    } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
                const notification = new Notification("Incoming Call from " + name, { body: number });
            }
        });
    }
}

// ----------------------------------------
// Creating WebRTC Client for call management
// ----------------------------------------
const establishWebRTCClient = () => {
    if (!callClient.value) {
        const info = TelnyxRTC.webRTCInfo() as IWebRTCInfo;
        const isWebRTCSupported = info.supportWebRTC;

        socket.on("call_created:user_" + authStore.user?.id + "_call", (...args) => {
            if (args.length > 0) {
                let dbCall = args[0].content;
                if (dbCall.direction == 'outbound') {
                    dialing.value = false;
                }
                callstore.updateDBCallObject(dbCall);

                setTimeout(() => {
                    if (calls.value.length < 2 && callAudioRingElement.value) {
                        callAudioRingElement.value.play();
                    }
                }, 1500);
            }
        });

        if (isWebRTCSupported) {
            callClient.value = new TelnyxRTC({
                // login_token: authStore.user.telnyx_webrtc_token,
                login: authStore.user.telnyx_webrtc_username,
                password: authStore.user.telnyx_webrtc_password,
            });

            callClient.value.enableMicrophone();
            callClient.value.checkPermissions(true, false);
            if (callAudio.value) {
                callClient.value.remoteElement = callAudio.value;
            }

            callClient.value
                .on('telnyx.ready', () => {
                    callClientStatus.value = 'Connected';
                })
                .on('telnyx.notification', (notification: INotification) => {
                    let call = notification.call as any;
                    const isDuplicate = calls.value.some((callEvent) => {
                        return callEvent.contactNumber && (callEvent.callJSObj?.id == call.id) || (callEvent.contactNumber == call.options.remoteCallerNumber);
                    });
                    if (!isDuplicate) {
                        callstore.addCallEvent(call, call.options.remoteCallerNumber);
                    }

                    handleCallNotifications(notification);

                })
                .on('telnyx.socket.close', function () {
                    console.error('Socket Close');
                    if (callClient.value) {
                        callClientStatus.value = 'Disconnected';
                        callClient.value.disconnect();
                        detachListeners();
                        webRTCConnect();
                    }
                })
                .on('telnyx.error', function (error) {
                    console.error('telnyx error:', error);
                    if (callClient.value) {
                        callClientStatus.value = 'Disconnected';
                        callClient.value.disconnect();
                        detachListeners();
                        webRTCConnect();
                    }
                });

            webRTCConnect();
            callAudioRingElement.value = document.getElementById('callRingtone') as HTMLAudioElement;

        }
        else {
            toast.display('This browser does not support calling feature');
        }
    }
}

const checkValidUser = () => {
    return authStore.user && authStore.userHasRole(['member']) && authStore.user.telnyx_webrtc_token && (authStore.user.id == 11 || authStore.user.id == 17);
}

const webRTCConnect = () => {
    if (callClient.value) {
        callClient.value.connect();
    }
}

// ----------------------------------------
// Watching user logged In State
// ----------------------------------------
watch(() => authStore.isLoggedIn, (value) => {
    if (authStore.isLoggedIn && checkValidUser()) {
        fetchPhoneNumbers();
        establishWebRTCClient();
    }
});

// ----------------------------------------
// Changing Audio In and Audio Out Device
// ----------------------------------------
const audioOutDevices = ref<any>([]);
const audioInDevices = ref<any>([]);
const selectedAudioInDevice = ref(localStorage.getItem('audioIn') ? JSON.parse(localStorage.getItem('audioIn') as string) : null);
const selectedAudioOutDevice = ref(localStorage.getItem('audioOut') ? JSON.parse(localStorage.getItem('audioOut') as string) : null);

const changeAudioOutDevice = (device) => {
    if (callClient.value) {
        if (!selectedAudioOutDevice.value || (selectedAudioOutDevice.value && device.deviceId != selectedAudioOutDevice.value.deviceId)) {
            selectedAudioOutDevice.value = device;
            callClient.value.speaker = device.deviceId;
            localStorage.setItem('audioOut', JSON.stringify(device));
        }
    }
}

const changeAudioInDevice = (device) => {
    if (callClient.value) {
        if (!selectedAudioInDevice.value || (selectedAudioInDevice.value && device.deviceId != selectedAudioInDevice.value.deviceId)) {
            device = {
                deviceId: device.deviceId,
                label: device.label,
                groupId: device.groupId,
                kind: device.kind,
                micId: device.micId ? device.micId : helper.generateUniqueString(24),
                micLabel: device.micLabel ? device.micLabel : device.label
            };
            selectedAudioInDevice.value = device;
            callClient.value.setAudioSettings(device);
            localStorage.setItem('audioIn', JSON.stringify(device));
        }
    }
}

// ----------------------------------------
// Detaching all WebRTC Client Event Listeners
// ----------------------------------------
const detachListeners = () => {
    if (callClient.value) {
        callClient.value.off('telnyx.error');
        callClient.value.off('telnyx.ready');
        callClient.value.off('telnyx.notification');
        callClient.value.off('telnyx.socket.close');
    }
}

// ----------------------------------------
// Disconnecting WebRTC Client
// ----------------------------------------
const disconnect = () => {
    callClientStatus.value = 'Disconnecting...';
    if (callClient.value) {
        callClient.value.disconnect();
    }
}

// ----------------------------------------
// Handling WebRTC Client Events
// ----------------------------------------
const handleCallNotifications = (notification) => {

    let call = notification.call;

    switch (notification.type) {
        case 'callUpdate':
            handleCallUpdate(call);
            break;
        case 'userMediaError':
            toast.display('Permission denied or invalid audio/video params on `getUserMedia`', 'info' as TYPE)
            break;
    }
}

// ----------------------------------------
// Handling WebRTC Call Related Events
// ----------------------------------------
const errorMessageActive = ref<boolean>(false); // For checking the display state of error message
const handleCallUpdate = (call) => {

    if (call.direction == 'outbound' && call.sipCode && call.sipCode >= 400 && !errorMessageActive.value) {
        errorMessageActive.value = true;
        toast.display(sipErrorMessages.value[call.sipCode], 'info' as TYPE);
        callstore.endCall(call);
        setTimeout(() => {
            errorMessageActive.value = false;
        }, 8000);
    }
    switch (call.state) {
        case 'new': // Setup the UI
        case 'trying': // You are trying to call someone and he's ringing now
        case 'recovering': // Call is recovering from a previous session
        case 'requesting':
        case 'answering':
        case 'held':
            callstore.updateCallObject(call);
            break;
        case 'active': // Call has become active
            callstore.updateCallObject(call);
            callstore.setStartTime(call);
            if (callAudioRingElement.value) {
                callAudioRingElement.value.pause();
                callAudioRingElement.value.currentTime = 0;
            }
            break;
        case 'ringing':
            callstore.updateCallObject(call);
            let matchedCallObjects = calls.value.filter((tempCall) => {
                return call.id == tempCall.callJSObj.id;
            });
            if (matchedCallObjects.length > 0) {
                let matchedCall = matchedCallObjects[0];
                if (!matchedCall.notified && matchedCall.contact) {
                    showCallRinging();
                    callstore.callNotificationSent(matchedCall);
                    callNotification(matchedCall.contact.first_name, matchedCall.contact.phone);
                }
            }
            break;
        case 'hangup': // Call is over
        case 'destroy': // Call has been destroyed
        case 'purge':
            callEndProcess(call);
            callstore.endCall(call);
            break;
    }
}

// ----------------------------------------
// End or Hangup the Call
// ----------------------------------------
const callEndProcess = async (call) => {
    let duration = getCallTime(call);
    if (call.callDBObj?.id && duration > 0) {
        let data = {
            'duration': duration
        };

        await apiClient.post('call/processEndCall/' + call.callDBObj?.id, data)
            .then(async (response: { data: { message: string } }) => {
            })
            .catch(async (error) => {
                let errors = await useHttpErrorManager().handleError(error, true);
            });
    }
}

const hangUp = async () => {
    if (activeCall.value) {
        callEndProcess(activeCall.value);
        activeCall.value.callJSObj?.hangup();
    }
}

// ----------------------------------------
// Toggle Mute State of the call, Outgoing audio will be muted or unmuted
// ----------------------------------------
const toggleMuteCall = (call_id) => {
    let filteredCall = calls.value.filter((call) => {
        return call && call.contact?.id == call_id;
    });
    filteredCall.forEach(call => {
        if (!call.muted) {
            callstore.toggleMute(call_id);
            call.callJSObj.muteAudio();
        }
        else {
            callstore.toggleMute(call_id);
            call.callJSObj.unmuteAudio();
        }
    });
}

const endSpecificCall = (call_id) => {
    let filteredCall = calls.value.filter((call) => {
        return call && call.contact?.id == call_id;
    });
    filteredCall.forEach(call => {
        callEndProcess(call.callJSObj);
        call.callJSObj.hangup();
        callstore.removeCallObject(call_id);
    });

}

// ----------------------------------------
// Put the call Onhold
// ----------------------------------------
const holdCall = (call) => {
    if (call) {
        call.hold();
    }
}

// ----------------------------------------
// Making a new outbound call
// ----------------------------------------
const dialing = ref(false);
const call = async (phone, contact) => {

    if (authStore.user && authStore.user.balance <= 0) {
        toast.display('Insufficient Balance to Make this call', 'info' as TYPE);
        return;
    }

    contact = helper.cleanPhoneNumber(contact);
    if (!helper.isValidCleanedPhoneNumber(contact)) {
        toast.display('Please enter a valid recipient number.', 'info' as TYPE);
        return;
    }
    if (!phone) {
        phone = phone_numbers.value[0].phone_number;
    }
    phone = helper.cleanPhoneNumber(phone);
    if (!helper.isValidCleanedPhoneNumber(phone)) {
        toast.display('Please select a phone number to use for this call.', 'info' as TYPE);
        return;
    }

    if (dialing.value) {
        toast.display('Already attempting another outgoing call', 'info' as TYPE);
        return;
    }
    dialing.value = true;

    const params = {
        callerNumber: phone,
        destinationNumber: contact,
        audio: true,
        video: false
    };

    if (callClient.value) {
        const isDuplicate = calls.value.some((existingEvent) => {
            return existingEvent.contactNumber && existingEvent.contactNumber == contact;
        });
        if (!isDuplicate) {
            // put all active on hold
            let response = await apiClient.post('call/holdAllActiveCalls', {});
            calls.value.forEach(call => {
                if (call.status == 'active') {
                    holdCall(call);
                }
            });

            let call = callClient.value.newCall(params) as any;
            callstore.addCallEvent(call, contact, phone, 'active');
        }
        else {
            toast.display('You already have call in progress with this contact', 'info' as TYPE)
        }
    }
}

// ----------------------------------------
// Receive an Incoming Call
// ----------------------------------------
const answer = async () => {
    if (activeCall.value) {
        let response = await apiClient.post('call/answerCall/' + activeCall.value.callJSObj?.options.telnyxSessionId, {});
        calls.value.forEach(call => {
            if (call.status == 'active') {
                holdCall(call);
            }
        });
        activeCall.value.callJSObj?.answer();
    }
}

// ----------------------------------------
// Switch between different calls
// ----------------------------------------
const switchCall = (id) => {
    callstore.activateCall(id);
}

// ----------------------------------------
// Switch Onhold state of the call
// ----------------------------------------
const toggleHoldCall = () => {
    if (activeCall.value && activeCall.value.status == 'held') {
        calls.value.forEach(call => {
            if (call.status == 'active') {
                holdCall(call);
            }
        });
    }

    if (activeCall.value && activeCall.value.status == 'held') {
        activeCall.value.callJSObj?.unhold();
    }
    else if (activeCall.value && activeCall.value.status == 'active') {
        activeCall.value.callJSObj?.hold();
    }
}

// ----------------------------------------
// Enabling and Disabling Call Recording
// ----------------------------------------
const disableRecordClick = ref<boolean>(false);
const disableRecording = async (phone_id) => {
    disableRecordClick.value = true;
    if (activeCall.value && !disableRecordClick.value) {
        disableRecordClick.value = true;
        await apiClient.post('call/disableCallRecording/' + phone_id, {})
            .then(async (response: { data: { phoneNumber: PhoneNumber, message: string } }) => {
                callstore.updatePhoneNumberDetails(response.data.phoneNumber);
                disableRecordClick.value = false;
            })
            .catch(async (error) => {
                disableRecordClick.value = false;
                let errors = await useHttpErrorManager().handleError(error, true);
            });
    }
}

const enableRecording = async (phone_id) => {
    disableRecordClick.value = true;
    if (activeCall.value && !disableRecordClick.value) {
        disableRecordClick.value = true;
        await apiClient.post('call/enableCallRecording/' + phone_id, {})
            .then(async (response: { data: { phoneNumber: PhoneNumber, message: string } }) => {
                callstore.updatePhoneNumberDetails(response.data.phoneNumber);
                disableRecordClick.value = false;
            })
            .catch(async (error) => {
                disableRecordClick.value = false;
                let errors = await useHttpErrorManager().handleError(error, true);
            });
    }
}

// ----------------------------------------
// Component Core Data
// ----------------------------------------
const show = ref<boolean>(true);
const webRTCModal = ref<InstanceType<typeof BModal>>();
const closeModal = () => {
    if (webRTCModal.value) {
        webRTCModal.value?.hide();
    }
}

// ----------------------------------------
// Watching different reference variables in the component and performing specific actions
// like fetching phone numbers, initialing setting up audio in and out device.
// ----------------------------------------
watchEffect(async () => {
    if (dialPadState.value && phone_numbers.value.length == 0) {
        fetchPhoneNumbers();
    }
    if (callClient.value) {
        try {
            const audioOut = await callClient.value.getAudioOutDevices();
            const audioIn = await callClient.value.getAudioInDevices();
            audioOutDevices.value = audioOut;
            audioInDevices.value = audioIn;
            if (!callClient.value.speaker) {
                let outDevice = audioOutDevices.value.filter((device) => device.deviceId == 'default');
                if (outDevice.length > 0) {
                    changeAudioOutDevice(outDevice[0]);
                }
                else {
                    changeAudioOutDevice(audioOutDevices.value[0]);
                }
            }
            if (!selectedAudioInDevice.value) {
                let inDevice = audioInDevices.value.filter((device) => device.deviceId == 'default');
                if (inDevice.length > 0) {
                    changeAudioInDevice(inDevice[0]);
                }
                else {
                    changeAudioInDevice(audioInDevices.value[0]);
                }
            }
        } catch (error) {
            console.error('Error fetching audio devices:', error);
        }
    }
});

// ----------------------------------------
// Mounting the component
// ----------------------------------------
onMounted(async () => {

    if (Notification.permission !== "denied") {
        Notification.requestPermission();
    }

});

// ----------------------------------------
// Cleaning Actions before component unmount
// ----------------------------------------
onBeforeUnmount(() => {
    if (authStore.userHasRole(['member']) && authStore.user) {
        socket.off("call_created:user_" + authStore.user.id + "_call");
    }
});

// ----------------------------------------
// Cleaning Actions on unmount
// ----------------------------------------
onUnmounted(() => {
    clearInterval(intervalId.value);
})
</script>

<style lang="scss" scoped>
.keypad_row {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.digit {
    padding: 5px 15px;
    font-size: 2rem;
    cursor: pointer;
    width: 100%;
    margin: 3px;
    user-select: none;
}

.keypad_container {
    text-align: center;
    display: flex;
    flex-direction: column;
}

#output {
    font-size: 2rem;
    color: #1976d2;
    margin: 3px;
}

#call {
    background-color: #5e9a61;
    width: 100%;
}

.digit:active {
    background-color: #8d8c8c;
}

#call:hover {
    background-color: #47984b;
}
</style>
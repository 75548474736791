import { onBeforeUnmount, reactive } from "vue";
import { io } from "socket.io-client";

export const state = reactive({
    connected: false,
});

// forceNew: true
export const socket = io(`${import.meta.env.VITE_APP_URL}:3000/`, {
    transports: ["websocket"],
    upgrade: false,
    withCredentials: true,
    secure: true,
    timeout: 45000,
});

socket.on("connect", () => {
    // socket.id
});

socket.on("disconnect", (reason) => {
    console.error("Socket Connection disconnected. Attempting to reconnect...");
    socket.connect(); // Attempt reconnection
});

socket.on("timeout", () => {
    console.error("Socket Connection timed out. Attempting to reconnect...");
    socket.connect(); // Attempt reconnection
});

onBeforeUnmount(() => {
    socket.removeAllListeners();
});

// export const socket = io();

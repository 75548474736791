import { DirectiveBinding } from 'vue';

export default {
  mounted(el: HTMLElement, binding: DirectiveBinding<boolean | undefined>) {
    const mediaQuery = window.matchMedia('(max-width: 767px)');
    const isMobile = mediaQuery.matches;

    if (binding.value !== undefined) {
      binding.value = isMobile;
    }

    mediaQuery.addEventListener('change', (event) => {
      if (binding.value !== undefined) {
        binding.value = event.matches;
      }
    });
  },
};
import { CallObject, Call } from "@/utils/types";
import { defineStore } from "pinia";
import apiClient from "@/utils/axios";
import { useAppHelpers } from "@/composables/useAppHelpers";

export const useCallStore = defineStore("callStore", {
    state() {
        return {
            callEvents: [] as Array<CallObject>,
            activeCallId: null as null | number | undefined,
            dialPad: false as boolean,
            callModal: false as boolean,
            directOutboundCall: [] as { [key: string]: any }[],
            triggerOutboundDisconnection: false as boolean,
        };
    },
    getters: {
        isCallConnected(): boolean {
            return this.activeCallId != null;
        },
        callData(): null | CallObject {
            if (this.activeCallId) {
                return this.callEvents[this.activeCallId];
            }
            return null;
        },
    },
    actions: {
        async addCallEvent(
            call: any,
            contactNumber = null as string | number | null,
            phoneNumber = null as string | number | null,
            status = null as null | string
        ) {
            const isDuplicate = this.callEvents.some((callEvent) => {
                return callEvent.callJSObj && callEvent.callJSObj.id == call.id;
            });
            if (!isDuplicate) {
                if (
                    call.state != "destroy" &&
                    call.state != "purge" &&
                    call.state != "hangup"
                ) {
                    this.callEvents.push({
                        contact: null,
                        contactNumber: contactNumber,
                        type: contactNumber ? "outbound" : "inbound",
                        callJSObj: call,
                        callDBObj: null,
                        status: status ? status : call.state,
                        phone: null,
                        phoneNumber: phoneNumber,
                        startTime: null,
                        muted: false,
                        notified: false,
                    });
                }
            }
        },
        async getDBCallDataRequest(call: any) {
            let response = await apiClient.post(
                "call/fetchCall/" + call.options.telnyxSessionId,
                {}
            );
            return response.data.call as Call;
        },
        updateDBCallObject(dbCall) {
            this.callEvents = this.callEvents.map((callEvent) => {
                if (!callEvent.callDBObj && dbCall.call_session_id == callEvent.callJSObj.options.telnyxSessionId) {
                    callEvent.callDBObj = dbCall;
                    callEvent.contact = dbCall?.contact;
                    callEvent.contactNumber = dbCall?.contact?.phone;
                    callEvent.phone = dbCall?.phone_number;
                    callEvent.phoneNumber = dbCall?.phone_number?.phone_number;
                    callEvent.type = dbCall?.direction;
                    callEvent.startTime =
                        useAppHelpers().utcToLocalTimeInMilliSeconds(
                            dbCall?.call_start_time
                        );
                }

                return callEvent;
            });
            this.checkActiveCall();
            if (this.callEvents.length > 0) {
                this.callModal = true;
            }
        },
        updateCallObject(call: any) {
            this.callEvents = this.callEvents.map((callEvent) => {
                if (callEvent.callJSObj && callEvent.callJSObj.id == call.id) {
                    callEvent.callJSObj = call;
                    callEvent.status = call.state;
                }
                return callEvent;
            });
            this.checkActiveCall();
        },
        endCall(call: any) {
            this.callEvents = this.callEvents.filter((callEvent) => {
                return (
                    callEvent.callJSObj && callEvent.callJSObj.id !== call.id
                );
            });
            if (this.callEvents.length) {
                this.activeCallId = this.callEvents[0].contact?.id;
            } else {
                this.activeCallId = null;
                this.callModal = false;
            }
        },
        removeCallObject(id) {
            this.callEvents = this.callEvents.filter((callEvent) => {
                return callEvent.contact?.id != id;
            });
            if (this.callEvents.length) {
                this.activeCallId = this.callEvents[0].contact?.id;
            } else {
                this.activeCallId = null;
                this.callModal = false;
            }
        },
        checkActiveCall() {
            if (!this.activeCallId) {
                let filteredCalls = this.callEvents.filter((callEvent) => {
                    return callEvent.status == "active";
                });
                if (filteredCalls.length) {
                    this.activeCallId = filteredCalls[0].contact?.id;
                } else {
                    if (this.callEvents.length) {
                        this.activeCallId =
                            this.callEvents[
                                this.callEvents.length - 1
                            ].contact?.id;
                    } else {
                        this.callModal = false;
                    }
                }
            }
        },
        activateCall(id: number) {
            let filteredCalls = this.callEvents.filter((callEvent) => {
                return callEvent.contact && callEvent.contact.id === id;
            });
            if (filteredCalls.length) {
                this.activeCallId = filteredCalls[0].contact?.id;
            }
        },
        toggleMute(id) {
            this.callEvents = this.callEvents.map((callEvent) => {
                if (callEvent.contact && callEvent.contact.id === id) {
                    callEvent.muted = !callEvent.muted;
                }
                return callEvent;
            });
        },
        toggleDialpad() {
            this.dialPad = !this.dialPad;
        },
        hideDialpad() {
            this.dialPad = false;
        },
        showCallModal() {
            this.callModal = true;
        },
        hideCallModal() {
            this.callModal = false;
        },
        initiateCall(contact, phone) {
            if (this.directOutboundCall.length == 0) {
                this.directOutboundCall.push({
                    contact: contact,
                    phone: phone,
                });
            }
        },
        cleanDirectOutboundCallArray() {
            this.directOutboundCall = [];
        },
        disconnectAllOutbound() {
            this.triggerOutboundDisconnection = true;
        },
        resetDisconnectOutboundTrigger() {
            this.triggerOutboundDisconnection = false;
        },
        updatePhoneNumberDetails(phoneNumber) {
            this.callEvents = this.callEvents.map((callEvent) => {
                if (callEvent.phone && callEvent.phone.id == phoneNumber.id) {
                    callEvent.phone = phoneNumber;
                }
                return callEvent;
            });
        },
        setStartTime(call) {
            this.callEvents = this.callEvents.map((callEvent) => {
                if (callEvent.callJSObj.id == call.id) {
                    callEvent.startTime = new Date().getTime();
                }
                return callEvent;
            });
        },
        callNotificationSent(call) {
            this.callEvents = this.callEvents.map((callEvent) => {
                if (callEvent.callJSObj.id == call.callJSObj.id) {
                    callEvent.notified = true;
                }
                return callEvent;
            });
        },
    },
});

// Add Styles Globally
import "@/assets/scss/config/default/app.scss";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";
import "vue-toastification/dist/index.css";
import "vue-next-select/dist/index.min.css";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import 'flatpickr/dist/flatpickr.css';

import "bootstrap";

// Import Vue
import { createApp, markRaw } from "vue";
// Create Application and Adding main Vue Component
import App from "@/App.vue";
const app = createApp(App);

app.config.globalProperties.window = window;

// Adding Router to Application
import router from "@/router";
app.use(router);

import { createPinia } from "pinia";
const pinia = createPinia();
app.use(pinia);

// Directives Injection
import mobileDirective from "./directives/v-mobile";
import notMobileDirective from "./directives/v-not-mobile";
import maxLines from "./directives/maxLines";
app.directive("mobile", mobileDirective);
app.directive("not-mobile", notMobileDirective);
app.directive("max-lines", maxLines);

// Mounting the application
app.mount("#app");

import { vMaska } from "maska";
app.directive("maska", vMaska);

// Making Axios
import { AxiosKey } from "@/utils/symbols";
import http from "@/utils/axios";
app.provide(AxiosKey, http);

import { VueCookieNext } from "vue-cookie-next";
app.use(VueCookieNext);

import { BToastPlugin } from "bootstrap-vue-next";
app.use(BToastPlugin);

import VueApexCharts from "vue3-apexcharts";
import ApexCharts from "apexcharts";

app.config.globalProperties.$apexcharts = ApexCharts;

// Add this when into a TypeScript codebase
declare module "@vue/runtime-core" {
    interface ComponentCustomProperties {
        $apexcharts: typeof ApexCharts;
    }
}

app.use(VueApexCharts);

import Toast, { PluginOptions, POSITION } from "vue-toastification";
const options: PluginOptions = {
    transition: "my-custom-fade",
    maxToasts: 20,
    newestOnTop: true,
    position: POSITION.BOTTOM_RIGHT,
    timeout: 8000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false,
};

app.use(Toast, options);

import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "@/stores/auth-store";

// const Blank = () => import("@/views/blank/Index.vue");

const Privacy = () => import("@/views/company/Privacy.vue");
const Terms = () => import("@/views/company/Terms.vue");
const Disclaimer = () => import("@/views/company/Disclaimer.vue");
const Compliance = () => import("@/views/company/Compliance.vue");
// const Affiliate = () => import("@/views/company/Affiliate.vue");
const Refund = () => import("@/views/company/Refund.vue");

// const Landing = () => import("@/views/landing/Index.vue");
const Error404 = () => import("@/views/errors/404.vue");
const Dashboard = () => import("@/views/dashboard/Index.vue");
const ManagerDashboard = () => import("@/views/dashboard/Manager.vue");
// const Permissions = () => import("@/views/permissions/Index.vue");
const Users = () => import("@/views/users/Index.vue");
const SingleUser = () => import("@/views/users/Single.vue");
const Login = () => import("@/views/auth/signin/Index.vue");
const Register = () => import("@/views/auth/signup/Index.vue");
const Forgot = () => import("@/views/auth/forgot/Index.vue");
const Reset = () => import("@/views/auth/reset/Index.vue");
const Verify = () => import("@/views/auth/verify/Index.vue");
const Wallet = () => import("@/views/wallet/Index.vue");
const Subscription = () => import("@/views/subscription/UserSubscription.vue");
const SubscriptionStats = () => import("@/views/subscription_stats/Index.vue");
const ManageSubscription = () => import("@/views/subscription/ManageSubscription.vue");
const Workflows = () => import("@/views/workflows/Index.vue");
const ViewWorkflow = () => import("@/views/workflows/View.vue");
const ShareWorkflow = () => import("@/views/workflows/Share.vue");
const Contacts = () => import("@/views/contacts/Index.vue");
const CallLogs = () => import("@/views/calls/Index.vue");
const Messenger = () => import("@/views/messenger/Index.vue");
const MessageTemplate = () => import("@/views/message_templates/Index.vue");
const PhoneNumbers = () => import("@/views/phone_numbers/Index.vue");
const ScheduledMessages = () => import("@/views/scheduled_messages/Index.vue");
const Campaigns = () => import("@/views/campaigns/Index.vue");
const CreateCampaigns = () => import("@/views/campaigns/Create.vue");
const ViewCampaign = () => import("@/views/campaigns/View.vue");
const Tags = () => import("@/views/tags/Index.vue");
// const Tickets = () => import("@/views/tickets/UserTickets.vue");
// const ManageTickets = () => import("@/views/tickets/ManageTickets.vue");
// const SingleTicket = () => import("@/views/tickets/SingleTicket.vue");
const FAQ = () => import("@/views/faqs/UserFaqs.vue");
const ManageFAQ = () => import("@/views/faqs/ManageFaqs.vue");
const ManageDNC = () => import("@/views/dnc/Index.vue");
const Settings = () => import("@/views/user_settings/Index.vue");
const StopWords = () => import("@/views/stop_words/Index.vue");
const ManageSystemVariables = () => import("@/views/variables/Index.vue");
const Imports = () => import("@/views/imports/Index.vue");
const AutoResponse = () => import("@/views/auto_responder/Index.vue");
const ManageWorkflows = () => import("@/views/workflows/IndexAdmin.vue");
const ManageBrands = () => import("@/views/campaigns/BrandAdminIndex.vue");
const ManageCampaigns = () => import("@/views/campaigns/CampaignAdminIndex.vue");
const LandlineRemover = () => import("@/views/landline_remover/Index.vue");
const Logs = () => import("@/views/logs/Index.vue");

const routes = [
    // ---------------------------
    // Home Route
    // ---------------------------
    {
        name : "home",
        path : "/",
        component : Login,
        meta : {
            title : `Login`,
            authRequired : false,
            isAuthLayout : true
        }
    },
    {
        name : "privacy",
        path : "/privacy-policy",
        component : Privacy,
        meta : {
            title : `Privacy Policy`,
            authRequired : false,
            isAuthLayout : false
        }
    },
    {
        name : "terms",
        path : "/terms-and-conditions",
        component : Terms,
        meta : {
            title : `Terms and Conditions`,
            authRequired : false,
            isAuthLayout : false
        }
    },
    {
        name : "disclaimer",
        path : "/disclaimer",
        component : Disclaimer,
        meta : {
            title : `Disclaimer`,
            authRequired : false,
            isAuthLayout : false
        }
    },
    {
        name : "compliance",
        path : "/compliance",
        component : Compliance,
        meta : {
            title : `Compliance`,
            authRequired : false,
            isAuthLayout : false
        }
    },
    {
        name : "refund_policy",
        path : "/refund-policy",
        component : Refund,
        meta : {
            title : `Refund Policy`,
            authRequired : false,
            isAuthLayout : false
        }
    },
    // {
    //     name: "affiliate",
    //     path: "/affiliate",
    //     component: Affiliate,
    //     meta: {
    //         title: `Affiliate`,
    //         authRequired: false,
    //         isAuthLayout: false,
    //     }
    // },

    // ---------------------------
    // Panel Routes
    // ---------------------------
    {
        name : "dashboard",
        path : "/dashboard",
        component : Dashboard,
        meta : {
            title : `Dashboard`,
            authRequired : true,
            isAuthLayout : false,
            type : ["member", "manager", "admin"]
        }
    },
    {
        name : "wallet",
        path : "/wallet",
        component : Wallet,
        meta : {
            title : `Wallet`,
            authRequired : true,
            isAuthLayout : false,
            type : ["member", "manager", "admin"]
        }
    },
    {
        name : "subscription",
        path : "/subscription",
        component : Subscription,
        meta : {
            title : `Subscription`,
            authRequired : true,
            isAuthLayout : false,
            type : ["member", "manager", "admin"]
        }
    },
    {
        name : "workflows",
        path : "/text_drips",
        component : Workflows,
        meta : {
            title : `Text Drips`,
            authRequired : true,
            isAuthLayout : false,
            type : ["member", "manager", "admin"]
        }
    },
    {
        name : "tags",
        path : "/tags",
        component : Tags,
        meta : {
            title : `Tags`,
            authRequired : true,
            isAuthLayout : false,
            type : ["member", "manager", "admin"]
        }
    },
    {
        name : "auto_responder",
        path : "/auto_responder",
        component : AutoResponse,
        meta : {
            title : `Auto Responder`,
            authRequired : true,
            isAuthLayout : false,
            type : ["member", "manager", "admin"]
        }
    },
    {
        name : "workflow_view",
        path : "/text_drip/view/:id",
        component : ViewWorkflow,
        meta : {
            title : `Text Drip`,
            authRequired : true,
            isAuthLayout : false,
            type : ["member", "manager", "admin"]
        }
    },
    {
        name : "workflow_share_view",
        path : "/text_drip/shareLink/:id/:uuid",
        component : ShareWorkflow,
        meta : {
            title : `Shared Text Drip`,
            authRequired : true,
            isAuthLayout : false,
            type : ["member", "manager", "admin"]
        }
    },
    {
        name : "contacts",
        path : "/contacts",
        component : Contacts,
        meta : {
            title : `Contacts`,
            authRequired : true,
            isAuthLayout : false,
            type : ["member", "manager", "admin"]
        }
    },
    {
        name : "imports",
        path : "/imports",
        component : Imports,
        meta : {
            title : `Imports`,
            authRequired : true,
            isAuthLayout : false,
            type : ["member", "manager", "admin"]
        }
    },
    {
        name : "landline_remover",
        path : "/landline_remover",
        component : LandlineRemover,
        meta : {
            title : `Landline Remover`,
            authRequired : true,
            isAuthLayout : false,
            type : ["member", "manager", "admin"]
        }
    },
    {
        name : "calls",
        path : "/call_logs",
        component : CallLogs,
        meta : {
            title : `Call Logs`,
            authRequired : true,
            isAuthLayout : false,
            type : ["member", "manager", "admin"]
        }
    },
    {
        name : "messenger",
        path : "/messenger",
        component : Messenger,
        meta : {
            title : `Messenger`,
            authRequired : true,
            isAuthLayout : false,
            type : ["member", "manager", "admin"]
        }
    },
    {
        name : "message_templates",
        path : "/message_templates",
        component : MessageTemplate,
        meta : {
            title : `Message Templates`,
            authRequired : true,
            isAuthLayout : false,
            type : ["member", "manager", "admin"]
        }
    },
    {
        name : "phone_numbers",
        path : "/phone_numbers",
        component : PhoneNumbers,
        meta : {
            title : `Phone Numbers`,
            authRequired : true,
            isAuthLayout : false,
            type : ["member", "manager", "admin"]
        }
    },
    {
        name : "scheduled_messages",
        path : "/scheduled_messages",
        component : ScheduledMessages,
        meta : {
            title : `Scheduled Messages`,
            authRequired : true,
            isAuthLayout : false,
            type : ["member", "manager", "admin"]
        }
    },
    {
        name : "campaigns",
        path : "/campaigns",
        component : Campaigns,
        meta : {
            title : `Campaigns`,
            authRequired : true,
            isAuthLayout : false,
            type : ["member", "manager", "admin"]
        }
    },
    {
        name : "create_campaign",
        path : "/campaign/add",
        component : CreateCampaigns,
        meta : {
            title : `Create New Campaign`,
            authRequired : true,
            isAuthLayout : false,
            type : ["member", "manager", "admin"]
        }
    },
    {
        name : "campaign_view",
        path : "/campaign/view/:id",
        component : ViewCampaign,
        meta : {
            title : `10DLC Registration`,
            authRequired : true,
            isAuthLayout : false,
            type : ["member", "manager", "admin"]
        }
    },
    {
        name : "stop_words",
        path : "/stop_words",
        component : StopWords,
        meta : {
            title : `Stop Words`,
            authRequired : true,
            isAuthLayout : false,
            type : ["member", "manager", "admin"]
        }
    },
    {
        name : "faqs",
        path : "/faqs",
        component : FAQ,
        meta : {
            title : `FAQ's`,
            authRequired : true,
            isAuthLayout : false,
            type : "member"
        }
    },
    {
        name : "settings",
        path : "/settings",
        component : Settings,
        meta : {
            title : `Settings`,
            authRequired : true,
            isAuthLayout : false
        }
    },

    // ---------------------------
    // Admin Routes
    // ---------------------------
    {
        name : "manager_dashboard",
        path : "/app/manager/dashboard",
        component : ManagerDashboard,
        meta : {
            title : `Dashboard`,
            authRequired : true,
            isAuthLayout : false,
            type : "manager"
        }
    },
    {
        name : "manage_users",
        path : "/app/manage/users",
        component : Users,
        meta : {
            title : `Manage Users`,
            authRequired : true,
            isAuthLayout : false,
            type : "manager",
            scope_condition : "all",
            scope : ["users - manage"]
        }
    },
    {
        name : "manage_single_user",
        path : "/app/manage/user/:id",
        component : SingleUser,
        meta : {
            title : `Single User`,
            authRequired : true,
            isAuthLayout : false,
            type : "manager",
            scope_condition : "any",
            scope : ["users - manage", "messages - manage"]
        }
    },
    {
        name : "manage_subscription_stats",
        path : "/app/manage/subscription_stats",
        component : SubscriptionStats,
        meta : {
            title : `Subscription Stats`,
            authRequired : true,
            isAuthLayout : false,
            type : "manager",
            scope_condition : "all",
            scope : ["subscription stats - manage"]
        }
    },
    {
        name : "manage_subscriptions",
        path : "/app/manage/subscription",
        component : ManageSubscription,
        meta : {
            title : `Manage Subscription Packages`,
            authRequired : true,
            isAuthLayout : false,
            type : "manager",
            scope_condition : "all",
            scope : ["subscriptions - manage"]
        }
    },
    {
        name : "manage_faqs",
        path : "/app/manage/faqs",
        component : ManageFAQ,
        meta : {
            title : `Manage FAQ's`,
            authRequired : true,
            isAuthLayout : false,
            type : "manager",
            scope_condition : "all",
            scope : ["faqs - manage"]
        }
    },
    {
        name : "manage_dnc",
        path : "/app/manage/dnc",
        component : ManageDNC,
        meta : {
            title : `Manage DNC`,
            authRequired : true,
            isAuthLayout : false,
            type : "manager",
            scope_condition : "all",
            scope : ["dnc - manage"]
        }
    },
    {
        name : "manage_workflows",
        path : "/app/manage/text_drips",
        component : ManageWorkflows,
        meta : {
            title : `Manage Text Drips`,
            authRequired : true,
            isAuthLayout : false,
            type : "manager",
            scope_condition : "all",
            scope : ["text drips - manage"]
        }
    },
    {
        name : "manage_brands",
        path : "/app/manage/brands",
        component : ManageBrands,
        meta : {
            title : `Manage Brands`,
            authRequired : true,
            isAuthLayout : false,
            type : "manager",
            scope_condition : "all",
            scope : ["brands and campaigns - manage"]
        }
    },
    {
        name : "manage_campaigns",
        path : "/app/manage/campaigns/:brand_id",
        component : ManageCampaigns,
        props : route => ({
            brand_id : route.params.brand_id
        }),
        meta : {
            title : `Manage Campaigns`,
            authRequired : true,
            isAuthLayout : false,
            type : "manager",
            scope_condition : "all",
            scope : ["brands and campaigns - manage"]
        }
    },
    {
        name : "manage_system_variables",
        path : "/app/manage/system_variables",
        component : ManageSystemVariables,
        meta : {
            title : `Manage System Variables`,
            authRequired : true,
            isAuthLayout : false,
            type : "manager",
            scope_condition : "all",
            scope : ["variables - manage"]
        }
    },
    {
        name : "logs",
        path : "/app/manage/logs",
        component : Logs,
        meta : {
            title : `Manage Logs`,
            authRequired : true,
            isAuthLayout : false,
            type : "manager",
            scope_condition : "all",
            scope : ["logs - manage"]
        }
    },
    // {
    //     name: "manage_permissions",
    //     path: "/app/manage/permissions",
    //     component: Permissions,
    //     meta: {
    //         title: `Manage Permissions`,
    //         authRequired: true,
    //         isAuthLayout: false,
    //         type: 'manager',
    //         scope_condition: 'all',
    //         scope: ['permissions - manage']
    //     }
    // },

    // ---------------------------
    // Auth Routes
    // ---------------------------
    {
        name : "login",
        path : "/auth/login",
        component : Login,
        meta : {
            title : `Login`,
            authRequired : false,
            isAuthLayout : true
        }
    },
    {
        name : "register",
        path : "/auth/register",
        component : Register,
        meta : {
            title : `Register`,
            authRequired : false,
            isAuthLayout : true
        }
    },
    {
        name : "forgot",
        path : "/auth/forgot",
        component : Forgot,
        meta : {
            title : `Forgot Password`,
            authRequired : false,
            isAuthLayout : true
        }
    },
    {
        name : "reset",
        path : "/password/reset/:token",
        component : Reset,
        props : route => ({
            token : route.params.token,
            email : route.query.email
        }),
        meta : {
            title : `Reset Password`,
            authRequired : false,
            isAuthLayout : false
        }
    },
    {
        name : "verify",
        path : "/email/verify/:id/:hash",
        component : Verify,
        props : route => ({
            id : route.params.id,
            expires : route.query.expires,
            hash : route.params.hash,
            signature : route.query.signature
        }),
        meta : {
            title : `Email Verification`,
            authRequired : false,
            isAuthLayout : false
        }
    },
    // ---------------------------
    // Error Routes
    // ---------------------------
    {
        name : "error-404",
        path : "/:pathMatch(.*)*",
        component : Error404,
        meta : {
            title : `Error 404`,
            authRequired : false,
            isAuthLayout : false
        }
    }
    // ---------------------------
    // Tickets Routes
    // ---------------------------
    // {
    //     name: "tickets",
    //     path: "/tickets",
    //     component: Tickets,
    //     meta: {
    //         title: `Tickets`,
    //         authRequired: true,
    //         isAuthLayout: false,
    //         type: 'member'
    //     }
    // },
    // {
    //     name: "single_ticket",
    //     path: "/tickets/:uuid",
    //     component: SingleTicket,
    //     meta: {
    //         title: `Ticket Details`,
    //         authRequired: true,
    //         isAuthLayout: false,
    //     }
    // },
    // {
    //     name: "manage_tickets",
    //     path: "/app/manage/tickets",
    //     component: ManageTickets,
    //     meta: {
    //         title: `Manage Tickets`,
    //         authRequired: true,
    //         isAuthLayout: false,
    //         type: 'manager',
    //         scope_condition: 'all',
    //         scope: ['tickets - manage']
    //     }
    // },
];

const router = createRouter({history : createWebHistory(), routes});

router.beforeResolve(async (to, from, next) => {
    const authStore = useAuthStore();
    const {isLoggedIn, role, loadUser, canAccessRoute} = authStore;

    if ((to.meta.authRequired || to.meta.isAuthLayout) && !isLoggedIn) {
        await loadUser();
    }
    if (to.path === "/login") {
        return next({name : "login"});
    }

    const userCanAccessRoute = await canAccessRoute(to);

    const redirectToAppropriateDashboard = () => {
        if (role === "admin" || role === "manager") {
            return next({name : "manager_dashboard"});
        }
        else {
            return next({name : "dashboard"});
        }
    };

    if (!to.meta.authRequired || (to.meta.authRequired && userCanAccessRoute)) {
        if (to.meta.isAuthLayout && isLoggedIn) {
            return redirectToAppropriateDashboard();
        }
        else {
            if (role === "admin" || role === "manager") {
                return userCanAccessRoute ? next() : next({name : "manager_dashboard"});
            }
            else {
                return next();
            }
        }
    }
    else {
        if ((to.meta.authRequired || to.meta.isAuthLayout) && isLoggedIn) {
            return redirectToAppropriateDashboard();
        }
        else {
            if (to.meta.authRequired && !isLoggedIn) {
                return next({name : "login", query : {returnTo : to.name, params : to.params ? JSON.stringify(to.params) : undefined}});
            }
            else {
                return next({name : "home"});
            }
        }
    }
});

export default router;

<template>
    <div>
        <b-modal size="lg" @hidden="$emit('consentFormClose')" :no-close-on-backdrop="true" v-model="show" hide-header hide-footer :no-close-on-esc="true" class="v-modal-custom">
            <div class="modal-content">
                <div class="modal-header m-auto">
                    <h5 class="modal-title">SalesGodCrm Terms, Condition and Policies</h5>
                </div>
                <div class="modal-body text-center">
                    <p class="fs-15">By clicking the <b>"I Accept"</b> button, you agree to all the latest Terms, Conditions and Policies of the platform. Link to all of the platform terms, conditions and policies are listed below:</p>
                    <div class="my-4 text-center position-relative z-1">
                        <router-link :to="{ name: 'terms' }" target="_blank" class="fs-14 me-2">Terms and Conditions</router-link> | <router-link :to="{ name: 'privacy' }" target="_blank" class="fs-14 mx-2">Privacy Policy</router-link> |
                        <router-link :to="{ name: 'disclaimer' }" target="_blank" class="fs-14 mx-2">Disclaimer</router-link> |
                        <router-link :to="{ name: 'compliance' }" target="_blank" class="fs-14 mx-2">Compliance Policy</router-link> |
                        <router-link :to="{ name: 'refund_policy' }" target="_blank" class="fs-14 ms-2">Refund Policy</router-link>
                        <!-- | <router-link :to="{ name: 'affiliate' }" target="_blank" class="fs-14 ms-2">Affiliate Agreement</router-link> -->
                    </div>
                    <p class="fs-15">Please Agree to the above Terms, Conditions and Policies to Resume using the Platform.</p>
                </div>
                <div class="modal-footer m-auto">
                    <button type="button" class="btn btn-primary w-50" @click="submitConsent">I Agree</button>
                    <button type="button" class="btn btn-danger" @click="submitLogoutAction">Logout</button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script setup lang='ts'>
import { BModal } from 'bootstrap-vue-next';
import { useAuthStore } from '@/stores/auth-store';
import apiClient from '@/utils/axios';
import { onMounted, ref, watch } from 'vue';
import { useHttpErrorManager } from "@/composables/useHttpErrorManager";
import { useRouter } from 'vue-router';

const emit = defineEmits(['consentFormClose'])

const authStore = useAuthStore();
const router = useRouter();
const show = ref(false);

watch(() => authStore.isLoggedIn, (value) => {
    if (authStore.isLoggedIn) {
        showConsentForm();
    }

}, { immediate: true });

onMounted(() => {
    showConsentForm();
});

const showConsentForm = () => {
    if (authStore.user && authStore.userHasRole('member') && typeof (authStore.user.terms_accepted) == 'number' && authStore.user.terms_accepted == 0) {
        show.value = true;
    }
    else {
        show.value = false;
    }
}

const submitConsent = async () => {
    await apiClient.post('user/acceptTerms');
    await authStore.loadUser();
    if (authStore.user && authStore.user.terms_accepted && authStore.user.terms_accepted == 1) {
        show.value = false;
    }
}

const submitLogoutAction = async () => {
    if(authStore && authStore.userHasScope(['users - login']) && authStore.user && authStore.user.loggedin_user){
        try{
            await apiClient.post('user/logoutUser')
            authStore.onlyLoadUser();
            await router.push({ name: 'manage_users' });
        }
        catch(error){
            await useHttpErrorManager().handleError(error, true);
        }
    }
    else{
        await authStore.processLogout();
    }
}

</script>